@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Almarai%3Aital%2Cwght%400%2C400%3B0%2C700%3B0%2C800&display=swap");

.plasmic_tokens {
  --token-wXPWervhz: #102a42;
  --plasmic-token-bg-1: var(--token-wXPWervhz);
  --token-vMhTQOMEk: #243b53;
  --plasmic-token-bg-2: var(--token-vMhTQOMEk);
  --token-OrQH71Wuq: #324d67;
  --plasmic-token-bg-3: var(--token-OrQH71Wuq);
  --token-2R_8gjxWm: #48647f;
  --plasmic-token-bg-4: var(--token-2R_8gjxWm);
  --token-u8nKw-VdJ: #617e98;
  --plasmic-token-bg-5: var(--token-u8nKw-VdJ);
  --token-rVKRxxadM: #829ab0;
  --plasmic-token-bg-6: var(--token-rVKRxxadM);
  --token-Xq3rc7HMg: #829ab0;
  --plasmic-token-bg-7: var(--token-Xq3rc7HMg);
  --token-tCwKklo62: #bcccdc;
  --plasmic-token-bg-8: var(--token-tCwKklo62);
  --token-j0Uk5gReH: #dae2ec;
  --plasmic-token-bg-9: var(--token-j0Uk5gReH);
  --token-dPAbcx4Ft: #f1f5f8;
  --plasmic-token-bg-10: var(--token-dPAbcx4Ft);
  --token-RhhkBF78V: #ffffff;
  --plasmic-token-unnamed-style-token: var(--token-RhhkBF78V);
  --token-Td0tETSfc: var(--token-Xq3rc7HMg);
  --plasmic-token-unnamed-style-token-2: var(--token-Td0tETSfc);
}

.plasmic_default_styles {
  --mixin-cVPE_KjMQm_font-family: "Inter", sans-serif;
  --mixin-cVPE_KjMQm_font-size: 16px;
  --mixin-cVPE_KjMQm_font-weight: 400;
  --mixin-cVPE_KjMQm_font-style: normal;
  --mixin-cVPE_KjMQm_color: #535353;
  --mixin-cVPE_KjMQm_text-align: left;
  --mixin-cVPE_KjMQm_text-transform: none;
  --mixin-cVPE_KjMQm_line-height: 1.5;
  --mixin-cVPE_KjMQm_letter-spacing: normal;
  --mixin-cVPE_KjMQm_white-space: pre-wrap;
  --mixin-cVPE_KjMQm_user-select: text;
  --mixin-cVPE_KjMQm_text-decoration-line: none;
  --mixin-cVPE_KjMQm_text-overflow: clip;
  --mixin-WX5g9b9Wfz_color: #000000;
  --mixin-WX5g9b9Wfz_font-size: 24px;
  --mixin-WX5g9b9Wfz_font-weight: 600;
  --mixin-WX5g9b9Wfz_letter-spacing: -0.5px;
  --mixin-WX5g9b9Wfz_line-height: 1.3;
  --mixin-WX5g9b9Wfz_white-space: pre-wrap;
  --mixin-ekTsQo0Gvn_color: #000000;
  --mixin-ekTsQo0Gvn_font-size: 20px;
  --mixin-ekTsQo0Gvn_font-weight: 600;
  --mixin-ekTsQo0Gvn_letter-spacing: -0.3px;
  --mixin-ekTsQo0Gvn_line-height: 1.5;
  --mixin-ekTsQo0Gvn_white-space: pre-wrap;
  --mixin-oT20r8TCYs_color: #000000;
  --mixin-oT20r8TCYs_font-size: 16px;
  --mixin-oT20r8TCYs_font-weight: 600;
  --mixin-oT20r8TCYs_line-height: 1.5;
  --mixin-oT20r8TCYs_white-space: pre-wrap;
  --mixin-1Ed_64Fdzn_color: #0070f3;
  --mixin-1Ed_64Fdzn_white-space: pre-wrap;
  --mixin-YCfRsDmiQQ_border-left-color: #dddddd;
  --mixin-YCfRsDmiQQ_border-left-style: solid;
  --mixin-YCfRsDmiQQ_border-left-width: 3px;
  --mixin-YCfRsDmiQQ_color: #888888;
  --mixin-YCfRsDmiQQ_padding-left: 10px;
  --mixin-YCfRsDmiQQ_white-space: pre-wrap;
  --mixin-j5XgMXAcsd_color: #000000;
  --mixin-j5XgMXAcsd_font-size: 72px;
  --mixin-j5XgMXAcsd_font-weight: 900;
  --mixin-j5XgMXAcsd_letter-spacing: -4px;
  --mixin-j5XgMXAcsd_line-height: 1;
  --mixin-j5XgMXAcsd_white-space: pre-wrap;
  --mixin-ZbvfXNPssA_color: #000000;
  --mixin-ZbvfXNPssA_font-size: 48px;
  --mixin-ZbvfXNPssA_font-weight: 700;
  --mixin-ZbvfXNPssA_letter-spacing: -1px;
  --mixin-ZbvfXNPssA_line-height: 1.1;
  --mixin-ZbvfXNPssA_white-space: pre-wrap;
  --mixin-3wubTnWCbJ__color: #000000;
  --mixin-3wubTnWCbJ__font-size: 32px;
  --mixin-3wubTnWCbJ__font-weight: 600;
  --mixin-3wubTnWCbJ__letter-spacing: -0.8px;
  --mixin-3wubTnWCbJ__line-height: 1.2;
  --mixin-3wubTnWCbJ__white-space: pre-wrap;
  --mixin-wMfp8t7OEhi_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-wMfp8t7OEhi_border-bottom-color: #dddddd;
  --mixin-wMfp8t7OEhi_border-bottom-style: solid;
  --mixin-wMfp8t7OEhi_border-bottom-width: 1px;
  --mixin-wMfp8t7OEhi_border-left-color: #dddddd;
  --mixin-wMfp8t7OEhi_border-left-style: solid;
  --mixin-wMfp8t7OEhi_border-left-width: 1px;
  --mixin-wMfp8t7OEhi_border-right-color: #dddddd;
  --mixin-wMfp8t7OEhi_border-right-style: solid;
  --mixin-wMfp8t7OEhi_border-right-width: 1px;
  --mixin-wMfp8t7OEhi_border-top-color: #dddddd;
  --mixin-wMfp8t7OEhi_border-top-style: solid;
  --mixin-wMfp8t7OEhi_border-top-width: 1px;
  --mixin-wMfp8t7OEhi_border-bottom-left-radius: 3px;
  --mixin-wMfp8t7OEhi_border-bottom-right-radius: 3px;
  --mixin-wMfp8t7OEhi_border-top-left-radius: 3px;
  --mixin-wMfp8t7OEhi_border-top-right-radius: 3px;
  --mixin-wMfp8t7OEhi_font-family: "Inconsolata";
  --mixin-wMfp8t7OEhi_padding-bottom: 1px;
  --mixin-wMfp8t7OEhi_padding-left: 4px;
  --mixin-wMfp8t7OEhi_padding-right: 4px;
  --mixin-wMfp8t7OEhi_padding-top: 1px;
  --mixin-wMfp8t7OEhi_white-space: pre-wrap;
  --mixin-fQBl5CNNxh6_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-fQBl5CNNxh6_border-bottom-color: #dddddd;
  --mixin-fQBl5CNNxh6_border-bottom-style: solid;
  --mixin-fQBl5CNNxh6_border-bottom-width: 1px;
  --mixin-fQBl5CNNxh6_border-left-color: #dddddd;
  --mixin-fQBl5CNNxh6_border-left-style: solid;
  --mixin-fQBl5CNNxh6_border-left-width: 1px;
  --mixin-fQBl5CNNxh6_border-right-color: #dddddd;
  --mixin-fQBl5CNNxh6_border-right-style: solid;
  --mixin-fQBl5CNNxh6_border-right-width: 1px;
  --mixin-fQBl5CNNxh6_border-top-color: #dddddd;
  --mixin-fQBl5CNNxh6_border-top-style: solid;
  --mixin-fQBl5CNNxh6_border-top-width: 1px;
  --mixin-fQBl5CNNxh6_border-bottom-left-radius: 3px;
  --mixin-fQBl5CNNxh6_border-bottom-right-radius: 3px;
  --mixin-fQBl5CNNxh6_border-top-left-radius: 3px;
  --mixin-fQBl5CNNxh6_border-top-right-radius: 3px;
  --mixin-fQBl5CNNxh6_font-family: "Inconsolata";
  --mixin-fQBl5CNNxh6_padding-bottom: 3px;
  --mixin-fQBl5CNNxh6_padding-left: 6px;
  --mixin-fQBl5CNNxh6_padding-right: 6px;
  --mixin-fQBl5CNNxh6_padding-top: 3px;
  --mixin-fQBl5CNNxh6_white-space: pre-wrap;
  --mixin-7Y-CimnnhlO_display: flex;
  --mixin-7Y-CimnnhlO_flex-direction: column;
  --mixin-7Y-CimnnhlO_align-items: stretch;
  --mixin-7Y-CimnnhlO_justify-content: flex-start;
  --mixin-7Y-CimnnhlO_list-style-position: outside;
  --mixin-7Y-CimnnhlO_padding-left: 40px;
  --mixin-7Y-CimnnhlO_position: relative;
  --mixin-7Y-CimnnhlO_list-style-type: decimal;
  --mixin-7Y-CimnnhlO_white-space: pre-wrap;
  --mixin-T0OlqWI4xjd_display: flex;
  --mixin-T0OlqWI4xjd_flex-direction: column;
  --mixin-T0OlqWI4xjd_align-items: stretch;
  --mixin-T0OlqWI4xjd_justify-content: flex-start;
  --mixin-T0OlqWI4xjd_list-style-position: outside;
  --mixin-T0OlqWI4xjd_padding-left: 40px;
  --mixin-T0OlqWI4xjd_position: relative;
  --mixin-T0OlqWI4xjd_list-style-type: disc;
  --mixin-T0OlqWI4xjd_white-space: pre-wrap;
  --mixin-08CuDhOldON_color: #3291ff;
  --mixin-08CuDhOldON_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-TkQ97Kld3_box-shadow: 0px 4px 16px 0px var(--token-j0Uk5gReH);
  --plasmic-mixin-shadows-img_box-shadow: var(--mixin-TkQ97Kld3_box-shadow);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
.root_reset {
  font-family: var(--mixin-cVPE_KjMQm_font-family);
  font-size: var(--mixin-cVPE_KjMQm_font-size);
  font-weight: var(--mixin-cVPE_KjMQm_font-weight);
  font-style: var(--mixin-cVPE_KjMQm_font-style);
  color: var(--mixin-cVPE_KjMQm_color);
  text-align: var(--mixin-cVPE_KjMQm_text-align);
  text-transform: var(--mixin-cVPE_KjMQm_text-transform);
  line-height: var(--mixin-cVPE_KjMQm_line-height);
  letter-spacing: var(--mixin-cVPE_KjMQm_letter-spacing);
  white-space: var(--mixin-cVPE_KjMQm_white-space);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset :global(.__wab_expr_html_text) h4),
:where(.root_reset.plasmic_default__h4) {
  color: var(--mixin-WX5g9b9Wfz_color);
  font-size: var(--mixin-WX5g9b9Wfz_font-size);
  font-weight: var(--mixin-WX5g9b9Wfz_font-weight);
  letter-spacing: var(--mixin-WX5g9b9Wfz_letter-spacing);
  line-height: var(--mixin-WX5g9b9Wfz_line-height);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset :global(.__wab_expr_html_text) h5),
:where(.root_reset.plasmic_default__h5) {
  color: var(--mixin-ekTsQo0Gvn_color);
  font-size: var(--mixin-ekTsQo0Gvn_font-size);
  font-weight: var(--mixin-ekTsQo0Gvn_font-weight);
  letter-spacing: var(--mixin-ekTsQo0Gvn_letter-spacing);
  line-height: var(--mixin-ekTsQo0Gvn_line-height);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset :global(.__wab_expr_html_text) h6),
:where(.root_reset.plasmic_default__h6) {
  color: var(--mixin-oT20r8TCYs_color);
  font-size: var(--mixin-oT20r8TCYs_font-size);
  font-weight: var(--mixin-oT20r8TCYs_font-weight);
  line-height: var(--mixin-oT20r8TCYs_line-height);
}

:where(.root_reset .plasmic_default__a),
:where(.root_reset .a),
:where(.root_reset :global(.__wab_expr_html_text) a),
:where(.root_reset.plasmic_default__a) {
  color: var(--mixin-1Ed_64Fdzn_color);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset :global(.__wab_expr_html_text) blockquote),
:where(.root_reset.plasmic_default__blockquote) {
  color: var(--mixin-YCfRsDmiQQ_color);
  padding-left: var(--mixin-YCfRsDmiQQ_padding-left);
  border-left: var(--mixin-YCfRsDmiQQ_border-left-width)
    var(--mixin-YCfRsDmiQQ_border-left-style)
    var(--mixin-YCfRsDmiQQ_border-left-color);
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset :global(.__wab_expr_html_text) h1),
:where(.root_reset.plasmic_default__h1) {
  color: var(--mixin-j5XgMXAcsd_color);
  font-size: var(--mixin-j5XgMXAcsd_font-size);
  font-weight: var(--mixin-j5XgMXAcsd_font-weight);
  letter-spacing: var(--mixin-j5XgMXAcsd_letter-spacing);
  line-height: var(--mixin-j5XgMXAcsd_line-height);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset :global(.__wab_expr_html_text) h2),
:where(.root_reset.plasmic_default__h2) {
  color: var(--mixin-ZbvfXNPssA_color);
  font-size: var(--mixin-ZbvfXNPssA_font-size);
  font-weight: var(--mixin-ZbvfXNPssA_font-weight);
  letter-spacing: var(--mixin-ZbvfXNPssA_letter-spacing);
  line-height: var(--mixin-ZbvfXNPssA_line-height);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset :global(.__wab_expr_html_text) h3),
:where(.root_reset.plasmic_default__h3) {
  color: var(--mixin-3wubTnWCbJ__color);
  font-size: var(--mixin-3wubTnWCbJ__font-size);
  font-weight: var(--mixin-3wubTnWCbJ__font-weight);
  letter-spacing: var(--mixin-3wubTnWCbJ__letter-spacing);
  line-height: var(--mixin-3wubTnWCbJ__line-height);
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset :global(.__wab_expr_html_text) code),
:where(.root_reset.plasmic_default__code) {
  background: #f8f8f8;
  font-family: var(--mixin-wMfp8t7OEhi_font-family);
  border-radius: var(--mixin-wMfp8t7OEhi_border-top-left-radius)
    var(--mixin-wMfp8t7OEhi_border-top-right-radius)
    var(--mixin-wMfp8t7OEhi_border-bottom-right-radius)
    var(--mixin-wMfp8t7OEhi_border-bottom-left-radius);
  padding: var(--mixin-wMfp8t7OEhi_padding-top)
    var(--mixin-wMfp8t7OEhi_padding-right)
    var(--mixin-wMfp8t7OEhi_padding-bottom)
    var(--mixin-wMfp8t7OEhi_padding-left);
  border-top: var(--mixin-wMfp8t7OEhi_border-top-width)
    var(--mixin-wMfp8t7OEhi_border-top-style)
    var(--mixin-wMfp8t7OEhi_border-top-color);
  border-right: var(--mixin-wMfp8t7OEhi_border-right-width)
    var(--mixin-wMfp8t7OEhi_border-right-style)
    var(--mixin-wMfp8t7OEhi_border-right-color);
  border-bottom: var(--mixin-wMfp8t7OEhi_border-bottom-width)
    var(--mixin-wMfp8t7OEhi_border-bottom-style)
    var(--mixin-wMfp8t7OEhi_border-bottom-color);
  border-left: var(--mixin-wMfp8t7OEhi_border-left-width)
    var(--mixin-wMfp8t7OEhi_border-left-style)
    var(--mixin-wMfp8t7OEhi_border-left-color);
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset :global(.__wab_expr_html_text) pre),
:where(.root_reset.plasmic_default__pre) {
  background: #f8f8f8;
  font-family: var(--mixin-fQBl5CNNxh6_font-family);
  border-radius: var(--mixin-fQBl5CNNxh6_border-top-left-radius)
    var(--mixin-fQBl5CNNxh6_border-top-right-radius)
    var(--mixin-fQBl5CNNxh6_border-bottom-right-radius)
    var(--mixin-fQBl5CNNxh6_border-bottom-left-radius);
  padding: var(--mixin-fQBl5CNNxh6_padding-top)
    var(--mixin-fQBl5CNNxh6_padding-right)
    var(--mixin-fQBl5CNNxh6_padding-bottom)
    var(--mixin-fQBl5CNNxh6_padding-left);
  border-top: var(--mixin-fQBl5CNNxh6_border-top-width)
    var(--mixin-fQBl5CNNxh6_border-top-style)
    var(--mixin-fQBl5CNNxh6_border-top-color);
  border-right: var(--mixin-fQBl5CNNxh6_border-right-width)
    var(--mixin-fQBl5CNNxh6_border-right-style)
    var(--mixin-fQBl5CNNxh6_border-right-color);
  border-bottom: var(--mixin-fQBl5CNNxh6_border-bottom-width)
    var(--mixin-fQBl5CNNxh6_border-bottom-style)
    var(--mixin-fQBl5CNNxh6_border-bottom-color);
  border-left: var(--mixin-fQBl5CNNxh6_border-left-width)
    var(--mixin-fQBl5CNNxh6_border-left-style)
    var(--mixin-fQBl5CNNxh6_border-left-color);
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset :global(.__wab_expr_html_text) ol),
:where(.root_reset.plasmic_default__ol) {
  display: var(--mixin-7Y-CimnnhlO_display);
  flex-direction: var(--mixin-7Y-CimnnhlO_flex-direction);
  align-items: var(--mixin-7Y-CimnnhlO_align-items);
  justify-content: var(--mixin-7Y-CimnnhlO_justify-content);
  list-style-position: var(--mixin-7Y-CimnnhlO_list-style-position);
  padding-left: var(--mixin-7Y-CimnnhlO_padding-left);
  position: var(--mixin-7Y-CimnnhlO_position);
  list-style-type: var(--mixin-7Y-CimnnhlO_list-style-type);
  flex-column-gap: var(--mixin-7Y-CimnnhlO_flex-column-gap);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset :global(.__wab_expr_html_text) ul),
:where(.root_reset.plasmic_default__ul) {
  display: var(--mixin-T0OlqWI4xjd_display);
  flex-direction: var(--mixin-T0OlqWI4xjd_flex-direction);
  align-items: var(--mixin-T0OlqWI4xjd_align-items);
  justify-content: var(--mixin-T0OlqWI4xjd_justify-content);
  list-style-position: var(--mixin-T0OlqWI4xjd_list-style-position);
  padding-left: var(--mixin-T0OlqWI4xjd_padding-left);
  position: var(--mixin-T0OlqWI4xjd_position);
  list-style-type: var(--mixin-T0OlqWI4xjd_list-style-type);
  flex-column-gap: var(--mixin-T0OlqWI4xjd_flex-column-gap);
}

:where(.root_reset .plasmic_default__a:hover),
:where(.root_reset .a:hover),
:where(.root_reset :global(.__wab_expr_html_text) a:hover),
:where(.root_reset.plasmic_default__a:hover) {
  color: var(--mixin-08CuDhOldON_color);
}
