.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  background: #f5f7fa33;
  overflow: auto;
  min-width: 0;
}
.tabsContainer:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox___0OfLy {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  min-width: 0;
  padding: 8px;
}
@media (max-width: 768px) {
  .freeBox___0OfLy {
    padding: 0px;
  }
}
.freeBox__xDQR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (max-width: 768px) {
  .freeBox__xDQR {
    justify-content: center;
    align-items: center;
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-wrap: nowrap;
  align-content: stretch;
  flex-shrink: 1;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: var(--token-RhhkBF78V);
  flex-grow: 0;
  min-width: 380px;
  margin-top: 6px;
  padding: 8px;
}
@media (max-width: 768px) {
  .buttonsContainer {
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    min-width: 0;
    padding: 8px 0px;
  }
}
.tabButton__bLjBh:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  display: flex;
}
.text__hphlJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___3PyqG {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__ybg91 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.tabButton___6KLob:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  margin-left: 22px;
}
@media (max-width: 768px) {
  .tabButton___6KLob:global(.__wab_instance) {
    margin-left: 12px;
  }
}
.text__ecp1U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg__cbaBf {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__faMi2 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.tabButton__nJqb6:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  margin-left: 22px;
}
@media (max-width: 768px) {
  .tabButton__nJqb6:global(.__wab_instance) {
    margin-left: 12px;
  }
}
.text__cx2Fe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg__eOu9N {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__pBp83 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.tabButton__kHiMr:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  margin-left: 22px;
}
@media (max-width: 768px) {
  .tabButton__kHiMr:global(.__wab_instance) {
    margin-left: 12px;
  }
}
.text__sLrgN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___7B5P8 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___6RQxG {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.tabUnderline:global(.__wab_instance) {
  background: var(--token-2R_8gjxWm);
  height: 2px;
  object-fit: cover;
  flex-shrink: 1;
  font-size: 16px;
  display: flex;
  width: 400px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  min-width: 0;
  padding: 8px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__wjFdE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
  flex-shrink: 1;
  height: 100%;
  flex-grow: 0;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px 16px 8px;
}
.defaultEmpty:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: flex;
}
.tab00 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__yoQSv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;
  margin-top: 36px;
  min-width: 0;
  padding: 8px;
}
.profile:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: flex;
}
.reveal___9Spvz:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.tab1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px 8px 8px;
}
.freeBox__nSXez {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;
  margin-top: 0px;
  min-width: 0;
  padding: 0px;
}
.h1__pIczJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 24px;
  text-align: left;
  min-width: 0;
}
.h1__ij24C {
  user-select: text;
  text-transform: uppercase;
  font-family: "Almarai", sans-serif;
  font-size: 48px;
  letter-spacing: normal;
  margin-top: 0px;
}
.section {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Almarai", sans-serif;
  min-width: 0;
}
.text__vJXv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 24px;
  margin-bottom: 23px;
  min-width: 0;
}
.ul {
  flex-direction: row;
  display: flex;
  position: relative;
  list-style-type: none;
  padding: 0%;
}
.ul > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ul > :global(.__wab_flex-container) > *,
.ul > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.ul > :global(.__wab_flex-container) > picture > img,
.ul > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 12px;
}
.li__zlqBn {
  position: relative;
  display: flex;
  flex-direction: row;
}
.svg__kDwBk {
  width: 40px;
  height: 40px;
  max-width: 100%;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
}
.li__tJe0P {
  position: relative;
  display: flex;
  flex-direction: row;
}
.svg__r8C {
  width: 40px;
  height: 40px;
  max-width: 100%;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
}
.li__phXaf {
  position: relative;
  display: flex;
  flex-direction: row;
}
.svg__jbi6Y {
  width: 40px;
  height: 40px;
  max-width: 100%;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
}
.manifesto:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: flex;
}
.reveal__oakeC:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.tab2 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.freeBox__oL0Tx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;
  margin-top: 0px;
  min-width: 0;
  padding: 0px;
}
.text__jcOi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 14px;
  margin-top: 0px;
  font-weight: 600;
  min-width: 0;
}
.cite {
  position: relative;
  font-size: 14px;
}
.ol {
  flex-direction: column;
  display: flex;
  position: relative;
  text-transform: uppercase;
  margin-top: 36px;
  width: 100%;
  min-width: 0;
}
.ol > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.ol > :global(.__wab_flex-container) > *,
.ol > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.ol > :global(.__wab_flex-container) > picture > img,
.ol > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
@media (max-width: 768px) {
  .ol {
    margin-top: 24px;
  }
}
.li__az6Mi {
  position: relative;
}
.li__vc52 {
  position: relative;
}
.li___0Sw2T {
  position: relative;
}
.li__qs2 {
  position: relative;
}
.li__q3R2 {
  position: relative;
}
.li__q6YjC {
  position: relative;
}
.career:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: flex;
}
.freeBox__cCeGz {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__cCeGz > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__cCeGz > :global(.__wab_flex-container) > *,
.freeBox__cCeGz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cCeGz > :global(.__wab_flex-container) > picture > img,
.freeBox__cCeGz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.text__kEfBb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 0px;
  min-width: 0;
}
.slider:global(.__wab_instance) {
  max-width: 100%;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 0px;
  width: 100%;
  min-width: 0;
}
.img__bfb52 {
  box-shadow: 0px 4px 16px 0px var(--token-j0Uk5gReH);
  position: relative;
  left: auto;
  top: auto;
  object-fit: cover;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
  margin-bottom: 0px;
  max-height: 100%;
  border-radius: 5px;
}
.img__bfb52 > picture > img {
  object-fit: cover;
}
.img__bfb52 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__liSh3 {
  box-shadow: 0px 4px 16px 0px var(--token-j0Uk5gReH);
  position: relative;
  object-fit: fill;
  left: auto;
  top: auto;
  object-position: 50% -1px;
  height: 100%;
  max-height: 342px;
  flex-grow: 0;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
}
.img__liSh3 > picture > img {
  object-fit: fill;
  object-position: 50% -1px;
}
.img__liSh3 > :global(.__wab_img-spacer) > img {
  object-fit: fill;
  object-position: 50% -1px;
}
.text__grIvb {
  position: relative;
  margin-top: calc(48px + 16px) !important;
}
.text___6Q41I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.img__nd74N {
  position: relative;
  max-width: 100%;
  left: auto;
  top: auto;
  box-shadow: 0px 4px 16px 0px var(--token-j0Uk5gReH);
  align-self: center;
  z-index: 1;
  width: 450px;
  border-radius: 5px;
}
.column__dN3Dd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  min-width: 0;
  padding: 8px;
}
.freeBox__vwFu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: auto;
  height: auto;
  min-width: 0;
  padding: 8px;
}
.freeBox__cgdBn {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  min-width: 0;
  padding: 8px;
}
.h5 {
  position: relative;
  width: 100%;
  max-width: 800px;
  left: auto;
  top: auto;
  flex-shrink: 0;
  flex-grow: 1;
  margin-bottom: 13px;
  min-width: 0;
}
.antdCollapse___50Fk:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: #ffffff00;
  width: 100%;
  min-width: 0;
}
.antdCollapsePanel__kKTi2:global(.__wab_instance) {
  object-fit: cover;
  flex-grow: 1;
  flex-shrink: 0;
  background: #ffffff00;
  width: 100%;
}
.text__klRxX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.detailsIb:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
}
.antdCollapse__hWghd:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: #ffffff00;
  width: 100%;
  min-width: 0;
}
.antdCollapsePanel__fWfVl:global(.__wab_instance) {
  object-fit: cover;
  flex-grow: 1;
  flex-shrink: 0;
  background: #ffffff00;
  width: 100%;
}
.text__m5EOu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__juEeK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.antdCollapse___7B45U:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: #ffffff00;
  width: 100%;
  min-width: 0;
}
.antdCollapsePanel__pHv:global(.__wab_instance) {
  object-fit: cover;
  flex-grow: 1;
  flex-shrink: 0;
  background: #ffffff00;
  width: 100%;
}
.text__jY5Kf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__nU5R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
