.root:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  padding: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.img___3Da1H {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___3Da1H > picture > img {
  object-fit: cover;
}
.img___3Da1H > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__ro4Vy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__ro4Vy > picture > img {
  object-fit: cover;
}
.img__ro4Vy > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
